/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2018 Schwartzco Inc.
        License: 1810-ZRQSHN
*/

@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('AtlasGrotesk-Light-Web.eot');
  src: url('AtlasGrotesk-Light-Web.eot?#iefix') format('embedded-opentype'), url('AtlasGrotesk-Light-Web.woff2') format('woff2'),
    url('AtlasGrotesk-Light-Web.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

.AtlasGrotesk-Light-Web {
  font-family: 'Atlas Grotesk Web';
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('Roboto-Regular.eot');
  src: url('Roboto-Regular.eot?#iefix') format('embedded-opentype'), url('Roboto-Regular.woff2') format('woff2'), url('Roboto-Regular.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

.Lato {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}
