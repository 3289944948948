/*Header & Navigation Styles
========================================================*/
#inbrace-nav {
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  -moz-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
@media (max-width: 989px) {
  #inbrace-nav {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.1);
  }
}
.nav-fade-on-scroll {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.1);
}
.navbar-brand img {
  width: 180px;
  transition: transform ease-in-out 0.65s;
}
.navbar-brand {
  transition: all ease-in-out 0.65s;
}
@media (max-width: 1300px) {
  .header .nav-item .nav-link {
    font-size: 0.875rem;
  }
}
@media (max-width: 1100px) {
  .header .nav-item .nav-link {
    font-size: 0.8rem;
  }
}
@media (max-width: 989px) {
  .header .nav-item .nav-link {
    font-size: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(249, 181, 196, 1);
  }
}
#inbrace-locator:link,
#inbrace-locator:visited {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0.45rem;
  color: rgba(249, 181, 196, 1);
  transition: all 400ms cubic-bezier(0.445, 0.5, 0.55, 0.95);
}
#inbrace-locator:hover,
#inbrace-locator:active,
#inbrace-locator:focus,
#inbrace-locator.focus {
  color: rgba(249, 181, 196, 1);
  border: 1px solid rgba(249, 181, 196, 1);
}
/*Mobile Navigation Menu Styles
=====================================================*/
#inbrace-menu .icon-bar + .icon-bar {
  margin-top: 1px;
}
#inbrace-menu {
  background-color: transparent;
  border-radius: 50%;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
  width: 40px;
  height: 40px;
  padding: 0;
}
#inbrace-menu .icon-bar {
  display: block;
  border-radius: 0;
  margin: 0 auto;
  width: 14px;
  height: 2px;
  background-color: rgb(164, 164, 164);
  position: relative;
  transition: all 0.1s ease-in-out;
  transition: all 100ms cubic-bezier(0.445, 0.5, 0.55, 0.95);
}
#inbrace-menu .icon-bar:nth-of-type(2) {
  top: 1px;
}
#inbrace-menu .icon-bar:nth-of-type(3) {
  top: 2px;
}
#inbrace-menu.active .icon-bar:nth-of-type(1) {
  background-color: rgb(74, 74, 74);
  top: 3px;
  transform: rotate(45deg);
}
#inbrace-menu.active .icon-bar:nth-of-type(2) {
  background-color: transparent;
}
#inbrace-menu.active .icon-bar:nth-of-type(3) {
  background-color: rgb(74, 74, 74);
  top: -3px;
  transform: rotate(-45deg);
}
#inbrace-menu:hover .icon-bar,
#inbrace-menu:active .icon-bar {
  background-color: rgb(74, 74, 74);
}
#inbrace-menu:focus,
#inbrace-menu.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: none;
}
#inbrace-menu-collapse {
}

.collapse.in {
  display: block !important;
  float: left;
  width: 100%;
}
