.header {
  // padding-top: 25px;
  // padding-bottom: 35px;
}
.content {
  width: 100%;
}

.content .btn,
.footer .btn {
  padding: 0.75rem 2.85rem;
}

// @media (min-width: 768px) and (max-width: 1139px) {
//   .content {
//     min-height: 400px;
//   }
// }

// @media (min-width: 1280px) {
//   .content {
//     min-height: 440px;
//   }
// }

// @media (min-width: 1400px) {
//   .content {
//     width: 1370px;
//   }
// }

// @media screen and (min-width: 1600px) {
//   .content {
//     width: 1570px;
//   }
// }
// @media screen and (min-width: 1900px) {
//   .content {
//     width: 1870px;
//   }
// }

.btn-outline {
  background: transparent !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  transition: all 400ms cubic-bezier(0.445, 0.5, 0.55, 0.95) !important;
  color: rgba(164, 164, 164, 1) !important;
}
.btn-outline:hover {
  border: 1px solid rgba(249, 181, 195, 1) !important;
  color: rgba(74, 74, 74, 1) !important;
}
