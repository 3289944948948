@import '~bootstrap/scss/bootstrap';
@import './static/fonts/material-design-iconic-font/css/material-design-iconic-font.min.css';
@import './static/fonts/1810-ZRQSHN.css';
@charset "utf-8";
/* CSS Document */
@font-face {
  font-family: 'Material Design Iconic Font';
  src: url('./static/fonts/material-design-iconic-font/fonts/Material-Design-Iconic-Font.eot');
  src: url('./static/fonts/material-design-iconic-font/fonts/Material-Design-Iconic-Font.eot?#iefix') format('embedded-opentype'),
    url('./static/fonts/material-design-iconic-font/fonts/Material-Design-Iconic-Font.woff2') format('woff2'),
    url('./static/fonts/material-design-iconic-font/fonts/Material-Design-Iconic-Font.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('./static/fonts/AtlasGrotesk-Light-Web.eot');
  src: url('./static/fonts/AtlasGrotesk-Light-Web.eot?#iefix') format('embedded-opentype'), url('./static/fonts/AtlasGrotesk-Light-Web.woff2') format('woff2'),
    url('./static/fonts/AtlasGrotesk-Light-Web.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}
html,
body {
  background: #211b57 !important;
  overflow-x: hidden; /* Prevent scroll on narrow devices */
  font-family: 'Roboto', 'sofia-pro', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif !important;
  font-weight: 300;
  font-style: normal;
  color: rgba(74, 74, 74, 1);
}
strong {
  font-family: 'Roboto', 'sofia-pro', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif !important;
  font-weight: bold;
}

/*Default Text Styles
========================================================*/
@media (max-width: 1200px) {
  .content h1 {
    font-size: 2rem;
    line-height: 2.75rem;
  }
}
@media (max-width: 767px) {
  .content h1 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
.content h2 {
  font-size: 2rem;
  line-height: 2.75rem;
}
@media (max-width: 1200px) {
  .content h2 {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
}
@media (max-width: 767px) {
  .content h2 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
.content h3,
.content .h3 {
  font-size: 1.5rem;
  line-height: 2.25rem;
}
@media (max-width: 1200px) {
  .content h3,
  .content .h3 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
@media (max-width: 767px) {
  .content h3,
  .content .h3 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: bold;
  }
}
.content h4,
.content .h4 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.content p,
.content ul,
.content ol {
}
@media (max-width: 989px) {
  .content p,
  .content ul,
  .content ol,
  .content th,
  .content td {
    font-size: 1.25rem;
  }
}
.content {
  display: block;
}

iframe {
  border-style: none;
}

select {
  -moz-appearance: none;
}
