.bg-pink {
  background: #fff7f4;
}
.btn-pink {
  background-color: rgba(249, 181, 196, 1) !important;
  box-shadow: rgba(221, 181, 187, 0.35) 0px 7.5px 12px 0px !important;
  transition: all 400ms cubic-bezier(0.445, 0.5, 0.55, 0.95) !important;
  color: rgba(255, 255, 255, 1) !important;
  text-shadow: 1px 1px 1px rgba(209, 141, 156, 0.5) !important;
}
.btn-pink:hover {
  box-shadow: rgba(221, 181, 187, 0.35) 0px 15px 12px 0px !important;
  color: rgba(255, 255, 255, 1) !important;
  text-shadow: 0px 15px 12px 0px rgba(209, 141, 156, 1) !important;
}

.vh-50 {
  height: 50vh !important;
}

.bg-blue {
  background: #f2f6f9;
}

section {
  display: block;
}
