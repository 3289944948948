/*Footer Styles
========================================================*/
.footer p {
  color: rgba(164, 164, 164, 1);
}
.footer a:link,
.footer a:visited {
  color: rgba(164, 164, 164, 1);
}
.footer a:hover,
.footer a:active,
.footer a:focus,
.footer a.focus {
  color: rgba(74, 74, 74, 1);
  text-decoration: none;
}
.footer h5 {
  font-size: 1rem;
  color: rgba(74, 74, 74, 1);
}
@media (max-width: 1200px) {
  .footer h5,
  .footer p {
    font-size: 0.9rem;
  }
}
.footer .nav.flex-column .nav-item .nav-link {
  padding: 0.25rem 1rem 0.25rem 0rem;
  color: rgba(164, 164, 164, 1);
}
@media (max-width: 1200px) {
  .footer .nav.flex-column .nav-item .nav-link {
    font-size: 0.9rem;
  }
  .footer .nav .nav-item {
    font-size: 0.9rem;
  }
}
.footer .nav.flex-column .nav-item .nav-link:hover {
  color: rgba(74, 74, 74, 1);
}
.social-nav .nav-item .nav-link {
  padding: 0.45rem;
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: transparent;
  color: rgba(184, 187, 190, 1);
  color: rgb(164, 164, 164);
  opacity: 1;
  /*border: 1px solid rgba(214, 217, 220, 1);*/
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 400ms cubic-bezier(0.445, 0.5, 0.55, 0.95);
}
.social-nav .nav-item {
  margin: 0 0.3rem;
}
.social-nav .nav-item:first-child {
  margin-left: 0;
}
.social-nav .nav-item:last-child {
  margin-right: 0;
}
.social-nav .nav-item .nav-link:hover {
  border: 1px solid rgba(249, 181, 196, 1);
  color: rgb(74, 74, 74);
}

.text-pink {
  color: rgba(249, 181, 196, 1) !important;
}
