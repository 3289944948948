.content-header {
  display: block;
  color: #fff !important;
  font-size: 26px !important;
  text-align: center;
  font-family: 'Roboto', 'sofia-pro', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif !important;
}

.teal-span {
  color: #5ec3eb;
}

.required-span {
  color: #d8fa65;
}

.sub-header {
  display: block;
  color: #4a4a4a;
  opacity: 0.8;
  color: #fff;
  text-align: left;
  font-size: 1.1rem;
}

.form-content {
  width: 100%;
}

.input-label-form {
  margin-bottom: 10px;
  color: #4a4a4a;
  opacity: 0.8;
  font-size: 1rem;
  color: #fff;
}

.btn.btn-primary.btn-override {
  // height: 50px;
  text-align: center;
  font-size: 0.9375rem;
  line-height: 1.1;
  width: 150px;
  border: 1px solid #fff;
  border-radius: 0px;
  background: rgba(0, 0, 0, 0.25);
  color: #fff;
  padding: 0.625rem 2.5rem !important;
  margin-top: 10px;
  font-weight: bold;
}

.invalid-feedback {
  color: #ff0000 !important;
  opacity: 0.5 !important;
  font-size: 16px !important;
}

.form-group {
  margin-bottom: 30px;
}
.was-validated .form-control:valid,
.form-control.is-valid {
  background-color: #fff !important;
  background-image: none !important;
  border: 1px solid #ced4da !important;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
  border-color: #ff0000 !important;
  opacity: 0.5 !important;
}

.input-error {
  border-color: #e13146 !important;
  margin-bottom: 5px;
}

.error-message {
  color: #e13146 !important;
  font-size: 16px !important;
}

.form-container {
  margin-left: 20px;
  margin-right: 20px;
}

@media (min-width: 380px) and (max-width: 700px) {
  .form-control {
    width: 100% !important;
  }
  .form-group {
    width: 100% !important;
  }
  .form-content {
    width: 100% !important;
  }
  .content-header {
    font-size: 20px !important;
  }
  .success-header {
    font-size: 20px !important;
    text-align: center;
  }
}

@media (min-width: 700px) and (max-width: 2000px) {
  .form-control {
    max-width: 450px;
  }
}
